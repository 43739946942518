<template>

  <el-dialog
      v-model="additionalApplicationSettingsDialogVisible"
      class="modal-95-70-60"
      title="Настройка по операторам"
  >

    <client-sender-names-application-description />
    <div v-for="(oper, key) in SENDERS_OPERATOR_CONFIG">
      <client-sender-name-application-operator-form
          :operator-name="oper.name"
          :operator-key="key"
          :free-name-available="oper.freeNameAvailable"
          :ref="`operatorSpecificApplicationForm_${key}`"
      />
      <el-divider class="blue-divider"></el-divider>
    </div>
    <el-button type="success" plain @click="sendApplication" :loading="sendingRequest">Отправить заявку</el-button>
  </el-dialog>

  <el-row :gutter="20">
    <el-col :span="24">
      <el-card class="box-card no-border no-top-body-padding" style="margin-bottom: 20px">
        <el-row v-if="!$store.getters.isShortReg">
          <el-col :span="24">
            <h4 class="report-parameters-title">Добавить имя отправителя</h4>
            <el-form label-position="top" :rules="newSenderValidationRules" ref="newSenderForm" :model="newRequest">
              <el-form-item label="Тип">
                <el-select v-model="newRequest.type" class="full-width">
                  <el-option label="Для СМС" value="sender_name"/>
                  <el-option label="Для звонков" value="outbound_number"/>
                </el-select>
              </el-form-item>

              <el-form-item :label="newRequest.type === 'sender_name' ? 'Имя отправителя' : 'Исходящий номер'"
                            prop="name">
                <el-input v-model="newRequest.name"></el-input>
              </el-form-item>

              <div v-show="newRequest.type === 'sender_name'" class="el-form-item__label">
                <p class="no-margin-top ">Имя отправителя должно быть не короче 3 и не длиннее 11 символов.<br/>Только
                  буквы латинского алфавита, цифры и спецсимволы (точка, дефис, подчеркивание)<br/>В имени
                  обязатательно должны присутствовать буквы</p>
              </div>

              <el-form-item v-if="$store.getters.user.isPhysic() || newRequest.type !== 'sender_name'"  style="margin-bottom: 0px">
                <el-button type="success" plain @click="createRequest" :loading="sendingRequest">Отправить заявку
                </el-button>
              </el-form-item>

              <el-form-item v-else style="margin-bottom: 0px">
                <el-button type="success" plain @click="showAdditionalApplicationSettings">Продолжить</el-button>
              </el-form-item>

            </el-form>
          </el-col>
        </el-row>
      </el-card>
    </el-col>
  </el-row>

</template>

<script>

import ClientSenderNamesApplicationDescription from "./client-sender-name-application-description.component.vue";
import ClientSenderNameApplicationOperatorForm from "./client-sender-name-application-operator-form.component.vue";

export default {
  name: "ClientSenderNamesRequestForm",
  components: {ClientSenderNameApplicationOperatorForm, ClientSenderNamesApplicationDescription},
  methods: {
    createRequest() {
      this.$refs.newSenderForm.validate(valid => {
        if (!valid) return
        this.sendingRequest = true
        this.axios.post("/sender_names/createRequest", {
          name: this.newRequest.name.trim(),
          type: this.newRequest.type,
          description: this.newRequest.description.trim()
        })
            .then(resp => {
              this.sendingRequest = false
              this.$refs.newSenderForm.resetFields()
              if (resp.data.success) {
                this.$gNotify("Заявка подана", "success")
                this.$emit('onNameCreated')
              } else
                this.$gNotify("Такое имя отправителя уже зарегистрировано", "error")

            })
      })
    },
    showAdditionalApplicationSettings() {
      this.$refs.newSenderForm.validate(valid => {
        if (!valid) return
        this.additionalApplicationSettingsDialogVisible = true
      })
    },
    senderNameValidator(rule, value, callback) {
      if (!value || !value.trim())
        return callback(new Error(`Заполните ${this.newRequest.type === 'outbound_number' ? 'номер телефона' : 'имя отправителя'}`))
      if (this.newRequest.type === 'outbound_number') {
        if (!validatePhone(value.trim()))
          return callback(new Error(`Указан неверный номер телефона`))
      } else {
        if (value.trim().length < 3 || value.trim().length > 11 || !/^[0-9a-zA-Z\.\-\_]{3,11}$/gi.test(value.trim()) || !/[a-z]{1,11}/gi.test(value.trim()))
          return callback(new Error(`Имя отправителя не соответствует требованиям`))
      }
      callback()
    },
    async sendApplication() {
      this.sendingRequest = true
      let sendPayload = Object.assign({}, this.newRequest)
      if (sendPayload.type === 'sender_name') {
        sendPayload.operatorSettings = {}
        for (let k of Object.keys(SENDERS_OPERATOR_CONFIG)) {
          let operatorPayload = await this.$refs[`operatorSpecificApplicationForm_${k}`][0].getState()
          if (operatorPayload === "E_NO_FILES") {
            this.sendingRequest = false
            return this.$gNotify("Загрузите документы для выбранных операторов", "error")
          }
          sendPayload.operatorSettings[k] = operatorPayload
        }
      }
      console.log(sendPayload)

      this.axios.post("/sender_names/createRequest", sendPayload)
          .then(resp => {
            this.sendingRequest = false
            if (resp.data.success) {
              this.$refs.newSenderForm.resetFields()
              this.additionalApplicationSettingsDialogVisible = false
              this.$gNotify("Заявка подана", "success")
              this.$emit('onNameCreated')
            } else {
              if (resp.data.msg && resp.data.msg === "E_NEN_MONEY")
                this.$gNotify("Неодстаточно средств для подачи заявки", "error")
              if (resp.data.msg && resp.data.msg === "E_NAME_EXISTS")
                this.$gNotify("Такое имя отправителя уже зарегистрировано", "error")
            }
          })
    },
  },
  data() {
    return {
      sendingRequest: false,
      newRequest: {
        name: "",
        type: "sender_name",
        description: "",
      },
      newSenderValidationRules: {
        name: [
          {
            required: true,
            validator: this.senderNameValidator,
            trigger: 'change',
          }
        ],
        description: [
          {
            required: true,
            message: 'Заполните описание заявки',
            trigger: 'change',
          }
        ]
      },
      additionalApplicationSettingsDialogVisible: false,
      SENDERS_OPERATOR_CONFIG
    }
  }
}

</script>
