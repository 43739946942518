<template>
  <div>
    <!--    <el-row>-->
    <!--      <el-col :span="24" style="padding-left: 20px">-->
    <!--        <h4>Общий отчет</h4>-->
    <!--      </el-col>-->
    <!--    </el-row>-->
<!--    <h4 class="report-parameters-title" v-show="!isAdminView">Параметры отчета</h4>-->
    <el-row :gutter="40">
      <el-col :md="isAdminView ? 6 : 12" :xs="24">

        <TimeSearch
            :full-width-select="true"
            :free-date-time="true"
            :month="true"
            :short-date="!!isAdminView"
            :show-last-month="!isAdminView"
            :small="isAdminView"
            ref="timeSearch"
        />

      </el-col>
      <el-col :md="12" :xs="24" v-if="!isAdminView">
        <el-form label-position="top">
          <el-form-item label="Тип сообщения">
            <el-select v-model="messageType" class="full-width">
              <el-option value="" label="Все"></el-option>
              <el-option value="sms" label="СМС"></el-option>
              <!--              <el-option value="viber" label="Viber"></el-option>-->
              <el-option value="vk" label="ВК/ОК"></el-option>
              <el-option value="telegram" label="Telegram"></el-option>
              <!--              <el-option value="call" label="Звонки"></el-option>-->
            </el-select>
          </el-form-item>
          <el-form-item label="Группировка">
            <el-select v-model="groupBy" class="full-width" :multiple="true">
              <el-option
                  v-for="opt in groupOptions"
                  :key="opt.value + '__opt'"
                  :value="opt.value" :label="opt.key"
              ></el-option>
            </el-select>
          </el-form-item>
          <!--          <el-form-item label="Название рассылки">-->
          <!--            <el-select v-model="deliveryId" class="full-width">-->
          <!--              <el-option value="" label="Все"></el-option>-->
          <!--              <el-option-->
          <!--                  v-for="delivery in allDeliveries"-->
          <!--                  :key="delivery._id"-->
          <!--                  :value="delivery._id"-->
          <!--                  :label="delivery.name"-->
          <!--              ></el-option>-->

          <!--            </el-select>-->
          <!--          </el-form-item>-->

        </el-form>
      </el-col>

      <el-col :md="6" :xs="24" v-if="isAdminView">
        <el-form label-position="top">
          <el-form-item label="Группировка" size="small" class="bold-label black-label" style="margin-bottom: 60px !important; ">
            <el-select v-model="groupBy" class="full-width" :multiple="true">
              <el-option
                  v-for="opt in groupOptions.concat(groupAdminOptions)"
                  :key="opt.value + '__opt'"
                  :value="opt.value" :label="opt.key"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <slot
            name="admin-settings-user-select"
            :onUsersChange="p => extraSearchParams.users = p"
            :onManagersChange="p => extraSearchParams.managers = p"
        ></slot>

      </el-col>

      <el-col :md="6" :xs="24" v-if="isAdminView">
        <slot
            name="admin-settings-1"
            :onSenderChange="p => extraSearchParams.sender = p"
            :onOperatorsChange="p => extraSearchParams.operators = p"
            :onOperatorsGroupsChange="p => extraSearchParams.operatorsGroups = p"
            :onCountriesChange="p => extraSearchParams.countries = p"
        ></slot>
      </el-col>
      <el-col :md="6" :xs="24" v-if="isAdminView">
        <el-form label-position="top">
          <slot
              name="admin-settings-2"
              :onAgregatorChange="p => extraSearchParams.agregators = p"
              :onTrafficTypeChange="p => extraSearchParams.trafficTypes = p"
          ></slot>

          <el-form-item label="Тип сообщения" size="small">
            <el-select v-model="messageType" class="full-width">
              <el-option value="" label="Все"></el-option>
              <el-option value="sms" label="СМС"></el-option>
              <el-option value="vk" label="ВК/ОК"></el-option>
              <el-option value="telegram" label="Telegram"></el-option>
            </el-select>
          </el-form-item>


        </el-form>
      </el-col>
    </el-row>


    <el-button type="success" plain @click="getReport" :loading="reportLoading"
               :style="isAdminView ? 'margin: 10px 0px;' : 'margin-bottom: 60px; margin-top: 20px'">Применить
    </el-button>
    <el-row style="margin-bottom: 40px" v-show="reportReady && groupedReportDataV2.length">
      <el-col :span="24">
        <el-form-item label="Логарифмическое масштабирование" v-show="false">
          <el-switch v-model="yLogScale"/>
        </el-form-item>
        <div style="width: 100%; overflow-x:scroll">
          <div :style="`min-width: 1200px; ${isAdminView ? 'height:500px' : ''}`">
            <canvas id="acquisitions"></canvas>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row v-show="reportReady">
      <el-col :span="24">
        <el-button text class="text-button table-text-button" @click="exportTable()" style="margin-bottom: 10px">
          <font-awesome-icon icon="external-link-alt"/>&nbsp;Экспортировать
        </el-button>
        <el-table :data="groupedReportDataV2" style="width: 100%"
                  :summary-method="tableSummary"
                  show-summary
        >
          <el-table-column v-if="!groupByTableCols.length" label="" min-width="120" :formatter="r => ''"/>
          <el-table-column v-for="k in groupByTableCols" :label="dynamicColumnName(k)" min-width="120" :formatter="r => formatKey(r, k)"/>

          <el-table-column label="Отправлено" min-width="120" prop="counters.sent"/>
          <el-table-column label="Доставлено" min-width="120" prop="counters.delivered"/>
          <el-table-column label="Прочитано" min-width="120" prop="counters.read"/>
          <el-table-column label="Не получен статус" min-width="120" prop="counters.undetected"/>
          <el-table-column label="Не доставлено" min-width="120" prop="counters.undelivered"/>
          <el-table-column label="Просрочено" min-width="120" prop="counters.expired"/>
          <el-table-column label="Списано (p.)" min-width="120" prop="expenses.down"/>
          <!--          <el-table-column label="Вернули" prop="expenses.up" />-->
          <!--          <el-table-column label="Итого" prop="expenses.result" />-->

        </el-table>
      </el-col>
    </el-row>

  </div>
</template>

<script>

import TimeSearch from "@shared/components/reports/time-search.component"
import moment from "moment"
import Chart from 'chart.js/auto'
import iconv from "iconv-lite";

// ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

const SMS_TRAFFIC_TYPES = {
  'add': 'Рекламный',
  'service': 'Сервисный',
  'informational': 'Информационные СМС',
  'transactional': 'Транзакционные СМС',
  'authorisation': 'Авторизационные СМС',
  'international': 'Международные СМС',
  "templated_add" : "Рекламный с шаблоном",
  'undefined': 'N/A',
  '': "Мультиподпись"
}

export default {
  name: "SentMessagesStatistics",
  props: ["isAdminView", "allAgregators", "allManagers"],
  components: {
    TimeSearch,
  },
  watch: {
    groupedReportDataV2() {
      this.drawChart()
    },
    yLogScale() {
      this.drawChart()
    }
  },
  computed: {
    groupedReportDataV2(){
      if (!this.reportData.length) return []
      let internalGroupedAccumulator = {}
      for (let record of this.reportData) {
        /*
        if (this.messageType) {
          if (this.messageType === "vk" && record._id.channel !== "vk") continue
          if (this.messageType === "telegram" && record._id.channel !== "telegram") continue
          if (this.messageType === "sms" && !["sms", "", null].includes(record._id.channel)) continue
        }
        if (this.extraSearchParams.sender.length)
          if (!this.extraSearchParams.sender.includes(record._id.sender)) continue

        if (this.extraSearchParams.agregators.length)
          if (!this.extraSearchParams.agregators.includes(record._id.agregator_id)) continue

        if (this.extraSearchParams.trafficTypes.length)
          if (!this.extraSearchParams.trafficTypes.includes(record._id.traffic_type)) continue

        if (this.extraSearchParams.operators.length)
          if (!this.extraSearchParams.operators.includes(record._id.operator_name)) continue
        if (this.extraSearchParams.operatorsGroups.length)
          if (!this.extraSearchParams.operatorsGroups.includes(record._id.operator_group)) continue
        if (this.extraSearchParams.countries.length)
          if (!this.extraSearchParams.countries.includes(record._id.mcc)) continue
        */
        let key = this.getGroupingKeyV2(record)
        let status = this.getVerboseStatus(record._id.status, record._id.read)
        if (!internalGroupedAccumulator[key]) internalGroupedAccumulator[key] = {
          __key: key, counters: {sent: 0, delivered: 0, read: 0, undetected: 0, undelivered: 0, expired: 0},
          expenses: {down: 0, up: 0, result: 0}
        }
        internalGroupedAccumulator[key].counters.sent += record.msg_count
        internalGroupedAccumulator[key].counters[status] += record.msg_count
        internalGroupedAccumulator[key].expenses.down += record.expense
      }
      return Object.values(Object.keys(internalGroupedAccumulator).sort().reduce((acc, k) => {

        acc[k] = internalGroupedAccumulator[k]
        acc[k].expenses.down = parseFloat(acc[k].expenses.down).toFixed(2)
        return acc
      }, {}))
    },
    groupedReportData() {
      // console.log(this.extraSearchParams)
      if (!this.reportData.length) return []
      let internalGroupedAccumulator = {}
      for (let record of this.reportData) {
        // if(!record.manager || !record.manager.system_id || record.manager.system_id === "undefined") console.log(record)
        if (this.messageType) {
          if (this.messageType === "vk" && record._id.channel !== "vk") continue
          if (this.messageType === "telegram" && record._id.channel !== "telegram") continue
          if (this.messageType === "sms" && !["sms", "", null].includes(record._id.channel)) continue
        }
        if (this.extraSearchParams.sender.length)
          if (!this.extraSearchParams.sender.includes(record._id.sender)) continue

        if (this.extraSearchParams.agregators.length)
          if (!this.extraSearchParams.agregators.includes(record._id.agregator_id)) continue

        if (this.extraSearchParams.trafficTypes.length)
          if (!this.extraSearchParams.trafficTypes.includes(record._id.traffic_type)) continue

        if (this.extraSearchParams.operators.length)
          if (!this.extraSearchParams.operators.includes(record._id.operator_name)) continue
        if (this.extraSearchParams.operatorsGroups.length)
          if (!this.extraSearchParams.operatorsGroups.includes(record._id.operator_group)) continue
        if (this.extraSearchParams.countries.length)
          if (!this.extraSearchParams.countries.includes(record._id.mcc)) continue

        let key = this.getGroupingKey(record)
        let status = this.getVerboseStatus(record._id.status, record._id.read)
        if (!internalGroupedAccumulator[key]) internalGroupedAccumulator[key] = {
          __key: key, counters: {sent: 0, delivered: 0, read: 0, undetected: 0, undelivered: 0, expired: 0},
          expenses: {down: 0, up: 0, result: 0}
        }
        internalGroupedAccumulator[key].counters.sent += record.msg_count
        internalGroupedAccumulator[key].counters[status] += record.msg_count
        internalGroupedAccumulator[key].expenses.down += record.expense
      }
      return Object.values(internalGroupedAccumulator).map(v => {
        v.expenses.down = parseFloat(v.expenses.down).toFixed(2)
        return v
      })
    },

  },
  methods: {
    dynamicColumnName(k) {
      return this.groupOptions.concat(this.groupAdminOptions).find(g => g.value === k).key
    },
    tableSummary() {
      let data = this.groupedReportDataV2
      return [
        "Итого",...Array(Math.max(this.groupByTableCols.length - 1, 0)).fill(""),
        data.reduce((acc, v) => acc + v.counters.sent, 0),
        data.reduce((acc, v) => acc + v.counters.delivered, 0),
        data.reduce((acc, v) => acc + v.counters.read, 0),
        data.reduce((acc, v) => acc + v.counters.undetected, 0),
        data.reduce((acc, v) => acc + v.counters.undelivered, 0),
        data.reduce((acc, v) => acc + v.counters.expired, 0),
        parseFloat(data.reduce((acc, v) => acc + parseFloat(v.expenses.down), 0)).toFixed(2)]
    },
    formatKey(row, k) {
      let keys = row.__key.split("[__||__]")
      let retval = []
      for(let key of keys) {
        let [prefix, val] = key.split("|_|")
        if(k && prefix !== k) continue
        switch (prefix) {
          case "hour":
            retval.push(val)
            break
          case "day":
            retval.push(val)
            break
          case "week":
            retval.push(val)
            break
          case "month":
            retval.push(val)
            break
          case "sender":
            retval.push(val || "N/A")
            break
          case "country":
            retval.push((this.$store.getters.countries.find(c => String(c.country_code) === val) || {}).country_name || "N/A")
            break

          case "operator_group":
            if (val === "undetected") retval.push("Не определена")
            else retval.push((this.$store.getters.operatorsGroups.find(c => c._id === val) || {}).title || "N/A")
            break

          case "operator":
            retval.push(val === "undetected" ? "Не определён" : val)
            break
          case "channel":
            retval.push(val === "vk" ? "ВК/ОК" : val === 'telegram' ? "Telegram" : "СМС")
            break

          case "user":
            retval.push(val)
            break

          case "agregator":
            retval.push(val)
            break
            // case "zone":
            //   return val
          case "manager":
            console.log(val)
            let m = ((this.allManagers || []).find(a => a.system_id === val) || {info: {}, system_id: "N/A"})
            let s =`${m.system_id}  ${m.info.lastname || ""} ${(m.info.firstname || "").slice(0, 1)}. ${(m.info.patronymic || "").slice(0, 1)}.`
            retval.push(s)
            break

          case "traffic_type":
            retval.push(SMS_TRAFFIC_TYPES[val])
            break

          default:
            retval.push("")
            break

        }
      }
      return retval.join(" | ")
    },
    getReport() {
      this.reportLoading = true
      this.reportReady = false
      let dateFilter = this.$refs.timeSearch.getDateTimeFilter()
      this.axios.post("/reports/sentMessagesStats", {
        users: this.extraSearchParams.users,
        managers: this.extraSearchParams.managers,
        group: this.groupBy,
        filter: {
          messageType: this.messageType,
          ...this.extraSearchParams
        }
      }, {
        params: {
          date_from: dateFilter.from.format("DD.MM.YYYY HH:mm"),
          date_to: dateFilter.to.format("DD.MM.YYYY HH:mm"),
        }
      })
          .then(resp => {
            this.groupByTableCols = Array.from(this.groupBy)
            this.reportLoading = false
            this.reportReady = true
            this.reportData = resp.data.sort((v1, v2) => {
              return moment(v1._id.dayOfYear, "YYYY-MM-DD HH").valueOf() - moment(v2._id.dayOfYear, "YYYY-MM-DD HH").valueOf()
            })
          })
    },
    getGroupingKey(record) {
      let keys = []
      for(let __key of this.groupByTableCols) {
        switch (__key) {
          case "hour":
            keys.push("hour|_|" + moment(record._id.dayOfYear, "YYYY-MM-DD HH").format("DD.MM.YYYY HH"))
            break
          case "day":
            keys.push("day|_|" + moment(record._id.dayOfYear, "YYYY-MM-DD HH").format("DD.MM.YYYY"))
            break
          case "week":
            keys.push("week|_|" + moment(record._id.dayOfYear, "YYYY-MM-DD HH").format("ww/YYYY"))
            break
          case "month":
            keys.push("month|_|" + moment(record._id.dayOfYear, "YYYY-MM-DD HH").format("MMM YYYY"))
            break
          case "country":
            keys.push("country|_|" + record._id.mcc)
            break
          case "operator_group":
            keys.push("operator_group|_|" + record._id.operator_group)
            break
          case "operator":
            keys.push("operator|_|" + record._id.operator_name)
            break
          case "sender":
            keys.push("sender|_|" + record._id.sender)
            break
          case "channel":
            keys.push("channel|_|" + record._id.channel)
            break

          case "traffic_type":
            keys.push("traffic_type|_|" + record._id.traffic_type)
            break
          case "manager":
            keys.push("manager|_|" + (record._id.manager || "N/A").system_id)
            break
            // case "zone":
            //   return "zone|_|" + record._id.channel
          case "agregator":
            keys.push("agregator|_|" + ((this.allAgregators || []).find(a => a._id === record._id.agregator_id) || {name: "N/A"}).name)
            break
          case "user":
            keys.push("user|_|" + record._id.user_system_id)
            break
        }
      }
      return keys.join("[__||__]")
    },
    getGroupingKeyV2(record) {
      let keys = []
      for(let __key of Object.keys(record._id)) {
        if(['status', 'read'].includes(__key)) continue
        switch (__key) {
          case "dayOfYear":
            keys.push("hour|_|" + moment(record._id.dayOfYear, "YYYY-MM-DD HH").format("DD.MM.YYYY HH"))
            break
          case "dayOfYearNoHour":
            keys.push("day|_|" + moment(record._id.dayOfYearNoHour, "YYYY-MM-DD").format("DD.MM.YYYY"))
            break
          case "weekOfYear":
            keys.push("week|_|" +record._id.weekOfYear)
            break
          case "monthOfYear":
            keys.push("month|_|" + moment(record._id.monthOfYear, "YYYY-MM").format("MMM YYYY"))
            break
          case "mcc":
            keys.push("country|_|" + record._id.mcc)
            break
          case "operator_group":
            keys.push("operator_group|_|" + record._id.operator_group)
            break
          case "operator_name":
            keys.push("operator|_|" + record._id.operator_name)
            break
          case "sender":
            keys.push("sender|_|" + record._id.sender)
            break
          case "channel":
            keys.push("channel|_|" + record._id.channel)
            break

          case "traffic_type":
            keys.push("traffic_type|_|" + record._id.traffic_type)
            break
          case "manager":
            keys.push("manager|_|" + record._id.manager || "N/A")
            break
            // case "zone":
            //   return "zone|_|" + record._id.channel
          case "agregator_id":
            keys.push("agregator|_|" + ((this.allAgregators || []).find(a => a._id === record._id.agregator_id) || {name: "N/A"}).name)
            break
          case "user_system_id":
            keys.push("user|_|" + record._id.user_system_id)
            break
        }
      }
      return keys.join("[__||__]")
    },
    getVerboseStatus(recordStatus, isRead) {
      switch (recordStatus) {
        case -1:
          return "undetected"
        case 1:
          return "undetected"
        case 2:
          return isRead ? "read" : "delivered"
        case 6:
          return "delivered"
        case 3:
          return "expired"
        case 5:
          return "undelivered"
        case 7:
          return "undelivered"
        case 8:
          return "undelivered"
        default:
          return "undetected"
      }
    },
    drawChart() {
      console.log(`Draw requested. Drawing: ${this.drawing} | Instance: ${!!this.chartInstance}`)
      if (this.drawing) return
      this.drawing = true
      let __data = this.groupedReportDataV2
      let datasets = [
        {
          label: 'Доставлено',
          data: __data.map(d => d.counters.delivered),
          backgroundColor: "#006400"
        },
        {
          label: 'Прочитано',
          data: __data.map(d => d.counters.read),
          backgroundColor: "#00AA55"
        },
        {
          label: 'Не получен статус',
          data: __data.map(d => d.counters.undetected),
          backgroundColor: "#59a7d7"
        },
        {
          label: 'Не доставлено',
          data: __data.map(d => d.counters.undelivered),
          backgroundColor: "#FF0000"
        },
        {
          label: 'Просрочено',
          data: __data.map(d => d.counters.expired),
          backgroundColor: "#FF9900"
        }
      ]
      let chartData = {
        labels: __data.map(d => this.formatKey(d)),
        datasets
      }
      if (this.chartInstance)
        this.chartInstance.destroy()

      this.chartInstance = new Chart(
          document.getElementById('acquisitions'),
          {
            type: 'bar',
            options: {
              scales: {
                x: {stacked: true, grid: {display: false}},
                y: {
                  stacked: true, type: this.yLogScale ? 'logarithmic' : 'linear', grid: {display: false},
                  ticks: {
                    callback: function (value) {
                      if (String(value).includes(".")) return ""
                      return value
                    }
                  }
                },

              },
              plugins: {
                legend: {align: "start"},
              },
              animation: false,
              maintainAspectRatio: !this.isAdminView
            },
            data: chartData,
            plugins: [{
              beforeInit: function (chart, options) {
                const originalFit = chart.legend.fit;
                chart.legend.fit = function fit() {
                  originalFit.bind(chart.legend)();
                  this.height += 30;
                }
              },
              afterRender: () => {
                this.drawing = false
              }
            }]
          }
      );

      // this.drawing = false
    },
    exportTable() {
      let csvContent = [
          iconv.encode(`${this.groupByTableCols.map(k => this.dynamicColumnName(k)).join(";")}${this.groupByTableCols.length ? ';' : ''}Отправлено;Доставлено;Прочитано;Не получен статус;Не доставлено;Просрочено;Списано (p.)\n`, "win1251"),
          ...this.groupedReportDataV2.map(a => {
            return [
                ...this.groupByTableCols.map(k => this.formatKey(a, k)), a.counters.sent, a.counters.delivered, a.counters.read, a.counters.undetected, a.counters.undelivered, a.counters.expired,
              a.expenses.down
            ].join(";") + "\n"
          })
      ]
      let blob = new Blob(csvContent, {type: 'text/csv'});

      let a = document.createElement('a');
      a.download = `Статистика.csv`;
      a.href = URL.createObjectURL(blob);
      a.click();
      // let encodedUri = encodeURI(iconv.encode(csvContent, "win1251"))
      // let link = document.createElement("a");
      // link.setAttribute("href", encodedUri);
      // link.setAttribute("download", "Статистика.csv");
      // document.body.appendChild(link);
      // link.click();
    }
  },
  mounted() {
    if (this.isAdminView) this.groupBy = ["user"]
  },
  data() {
    return {
      reportReady: false,
      reportLoading: false,
      groupBy: ["day"],
      groupByTableCols: [],
      deliveryId: "",
      messageType: "sms",

      allDeliveries: [],
      reportData: [],
      chartInstance: null,

      groupOptions: [
        {key: "Час", value: "hour"},
        {key: "День", value: "day"},
        {key: "Неделя", value: "week"},
        {key: "Месяц", value: "month"},
        {key: "Страна", value: "country"},
        {key: "Оператор", value: "operator"},
        {key: "Группа Операторов", value: "operator_group"},
        {key: "Отправитель", value: "sender"},
        {key: "Тип сообщения", value: "channel"},
        {key: "Тип трафика", value: "traffic_type"},
      ],
      groupAdminOptions: [

        {key: "Менеджер", value: "manager"},
        {key: "Пользователь", value: "user"},
        // {key: "Зона", value: "zone"},
        {key: "Канал", value: "agregator"},
      ],
      yLogScale: false,
      drawing: false,
      extraSearchParams: {
        users: [],
        managers: [],
        sender: [],

        operators: [],
        operatorsGroups: [],
        countries: [],
        agregators: [],
        trafficTypes: [],
      }
    }
  }
}

</script>
