<template>
  <div>
    <Breadcrumbs primary="Сообщения" secondary="Запрещенные номера"/>
    <form style="display: none" method="post" id="exportForm"
          :action="`${axios.defaults.baseURL}/blacklist/exportPhones`">
      <input name="__sg_client_token" type="hidden" :value="$store.getters.token"/>
    </form>
    <el-row :gutter="20">
      <el-col :span="24">
        <el-card class="box-card">

          <TableSpinner :loading="loading">
            <div style="display: flex;align-items: center;justify-content: space-between; margin-bottom : 40px">


              <el-button text class="text-button" @click="exportNumbers" style="padding: 0px; min-height: auto;height: auto !important; margin-left: 0px">
                <font-awesome-icon icon="external-link-alt"/>&nbsp;Экспортировать
              </el-button>
              <RemoveButton v-show="selectedNumbers.length > 0" @confirm="removeSelected()"
                            :loading="removingSelected">Удалить выбранные</RemoveButton>
            </div>


            <el-table
                ref="blacklistTable"
                class="flat-row"
                :data="blacklistNumbers" style="width: 100%;"
                @selection-change="handlePhoneSelected"
            >
              <el-table-column type="selection" width="80px" />
              <el-table-column prop="phone" label="Телефон"  min-width="150px"/>
<!--              <el-table-column label="" >-->
<!--                <template #default="scope">-->
<!--                  <RemoveButton @confirm="removeOne(scope.$index)" :loading="removingOne">Удалить</RemoveButton>-->

<!--                </template>-->
<!--              </el-table-column>-->
            </el-table>
            <div class="solo-pagination-container">
              <el-button class="button" type="success" @click="addFormVisible = true">
                <font-awesome-icon icon="plus" class="el-icon--left"></font-awesome-icon>
                Добавить номера
              </el-button>
              <el-pagination
                  :pager-count="3"
                  background
                  class="wide-page-size"
                  layout="sizes, prev, pager, next"
                  :total="totalPhones"
                  :page-sizes="[10, 20, 50, 100]"
                  :page-size="pageSize"
                  :hide-on-single-page="true"
                  @size-change="handlePageSizeChange"
                  @current-change="handleCurrentPageChange"
              >
              </el-pagination>
            </div>
          </TableSpinner>

        </el-card>
      </el-col>
    </el-row>


    <el-dialog
        v-model="addFormVisible"
        title="Добавить запрещенные номера"
        width="50%"
    >
<!--      <el-input v-model="newNumbersText" type="textarea" :rows="15"></el-input>-->
      <phones-list-input @values="setSplitedNumbers" label="" :rows="15" :show-add-seven="true"/>

      <el-divider></el-divider>
      <p>Введено уникальных номеров:&nbsp;<b>{{ amountOfNewNumbers }}</b></p>
      <p>Некорректных номеров:&nbsp;<b>{{ amountOfInvalidNewNumbers }}</b></p>

      <template #footer>
      <span class="dialog-footer">
        <el-button @click="addFormVisible = false">Отмена</el-button>
        <el-button type="success" @click="addNumbers" :loading="saving">Добавить номера</el-button>
      </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>


import {h} from 'vue'

import ActionsDropdown from "@shared/components/layout/actionsDropdown.component.vue";
import ActionButton from "@shared/components/layout/actionButton.component.vue";
import RemoveButton from "@shared/components/layout/removeButton.component.vue";
import PhonesListInput from "@shared/components/phones-list-input-component";


export default {
  name: "Blacklist",
  title: "Черный список",
  components: {
    PhonesListInput,
    RemoveButton,
    ActionButton,
    ActionsDropdown,
  },
  mounted() {
    this.refreshMyBlacklist()
  },
  watch: {
    // newNumbersText(newVal) {
    //   let splitted = newVal.replace(/(\r\n|\n|\r)/gm, ",").replace(/\s/g, ",").split(",").filter(v => v).map(p => patchPhone(p))
    //   this.newNumbersSplitted = Array.from(new Set(splitted))
    // }
  },
  computed: {
    amountOfNewNumbers() {
      return this.newNumbersSplitted.length
    },
    amountOfInvalidNewNumbers() {
      return this.newNumbersSplitted.filter(p => !validatePhone(p)).length
    }
  },
  methods: {
    setSplitedNumbers({values}){
      this.newNumbersSplitted = Array.from(new Set(values))
    },
    handlePageSizeChange(newSize) {
      this.pageSize = newSize
      this.refreshMyBlacklist(false)
    },
    handleCurrentPageChange(newPage) {
      this.currentPage = newPage
      this.refreshMyBlacklist(false)
    },
    clearSelection() {
      this.$refs.blacklistTable.clearSelection()
    },
    handlePhoneSelected(phones) {
      this.selectedNumbers = phones
    },
    refreshMyBlacklist(doCount = true) {
      this.loading = true
      let p1 = this.axios.get("/blacklist/getMyBlacklistedNumbers", {
        params: {
          pageIndex: this.currentPage,
          pageSize: this.pageSize
        }
      })
          .then(resp => {
            this.blacklistNumbers = resp.data

          })
      let toWait = [p1]
      if (doCount) {
        let p2 = this.axios.get("/blacklist/getMyBlacklistedNumbersCount")
            .then(resp => {
              this.totalPhones = resp.data.totalCount
            })
        toWait.push(p2)
      }
      Promise.all(toWait).then(() => {
        this.loading = false
      })
    },
    addNumbers() {
      this.saving = true
      let phonesToAdd = this.newNumbersSplitted.filter(p => validatePhone(p))
      this.axios.post("/blacklist/addPhones", {phones: phonesToAdd})
          .then(resp => {
            this.saving = false
            this.addFormVisible = false
            this.$gNotify("Запрещенные номера добавлены", "success", h('i', {style: 'color: teal'}, `Добавлено ${resp.data.added} номеров из ${phonesToAdd.length} указанных. ${phonesToAdd.length !== resp.data.added ? "Остальные уже присутствуют" : ""}`))
            this.currentPage = 1
            this.refreshMyBlacklist()
          })
          .catch(resp => {
            this.saving = false
            this.addFormVisible = false
          })
    },
    removeSelected() {
      this.removingSelected = true
      this.axios.post("/blacklist/removeManyFromMyList", {phoneIds: this.selectedNumbers.map(p => p._id)})
          .then(() => {
            this.currentPage = 1
            this.$gNotify("Запрещенные номера удалены", "success", h('i', {style: 'color: teal'}, `Удалено ${this.selectedNumbers.length} номеров`))
            this.removingSelected = false
            this.refreshMyBlacklist()
            this.clearSelection()
          })
          .then(() => {
            this.removingSelected = false
            this.refreshMyBlacklist()
            this.clearSelection()
          })
    },
    removeOne(index) {
      this.removingOne = true
      this.axios.post("/blacklist/removeOneFromMyList", {phoneId: this.blacklistNumbers[index]._id})
          .then(() => {
            this.removingOne = false
            this.refreshMyBlacklist()
          })
    },
    exportNumbers(){
      document.getElementById("exportForm").submit()
    }
  },
  data() {
    return {
      blacklistNumbers: [],
      selectedNumbers: [],
      addFormVisible: false,
      // newNumbersText: "",
      saving: false,
      removingSelected: false,
      removingOne: false,
      loading: false,
      newNumbersSplitted: [],
      pageSize: 50,
      currentPage: 1,
      totalPhones: 0
    }
  }
}

</script>

<style>
.wide-page-size.el-pagination .el-select .el-input {
  width: 190px;
}
</style>